
import { computed, defineComponent, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { FormState } from '@/views/form/step-form/model';

export default defineComponent({
  emits: ['finish'],
  setup(_, { emit }) {
    const store = useStore();

    const stepFormData = computed<FormState>(() => store.getters['stepForm/step']);

    const clearAllFormData = () => {
      return store.dispatch('stepForm/clear');
    };

    const handleFinish = (e: Event) => {
      e.preventDefault();
      clearAllFormData();
      emit('finish');
    };

    onUnmounted(() => {
      clearAllFormData();
    });

    return {
      stepFormData,

      handleFinish,
    };
  },
});
